import React, { Component } from 'react'
import { MdContactPhone, MdContactMail, MdWork } from 'react-icons/md'
import LinearProgress from '@material-ui/core/LinearProgress'
import './ContactUs.css';
import { postData } from '../helpers'
import { connect } from 'react-redux'

class ContactUs extends Component {

    state = {
        name: '',
        email: '',
        subject: '',
        contactNumber: '',
        message: '',
        sending: false,
        messageId: undefined
    }

    onValueChange = (e) => {
        const { name, value } = e.target

        this.setState({
            [name]: value
        })
    }

    onSubmitForm = (e) => {
        e.preventDefault()
        this.setState({ sending: true })
        const query = postData('sendquery', {
            'name': this.state.name,
            'email': this.state.email,
            'subject': this.state.subject,
            'contactNumber': this.state.contactNumber,
            'message': this.state.message,
        })

        query.then(response => {
            if (response.status === 200) {
                this.setState({
                    messageId: response.data.id,
                    sending: false
                })
            }
        })
    }

    render() {
        return (
            <div className='contact-us'>

                <div className='contact-section'>
                    <div className='contact-info'>
                        <img src='https://gamefamewebassests.s3.eu-west-2.amazonaws.com/contactus.jpeg' />
                    </div>

                    <div className='contact-info'>



                        <div className='heading'>
                            Send A Message
                        </div>
                        <p>

                            Your email address will not be published. Required fields are marked.
                        </p>

                        {
                            this.state.messageId !== undefined && (
                                <div className='message-recorded'>
                                    Thank you for contacting us. Your message has been recorded.
                                </div>
                            )
                        }

                        <form onSubmit={this.onSubmitForm}>
                            <input
                                name='name'
                                type='text'
                                onChange={this.onValueChange}
                                value={this.state.name}
                                placeholder='Name*' />
                            <input
                                name='email'
                                type='email'
                                onChange={this.onValueChange}
                                value={this.state.email}
                                placeholder='Email*' />
                            <input
                                name='subject'
                                type='text'
                                onChange={this.onValueChange}
                                value={this.state.subject}
                                placeholder='I would like to talk about*' />
                            <input
                                name='contactNumber'
                                type='number'
                                onChange={this.onValueChange}
                                value={this.state.age}
                                placeholder="Phone Number*" />
                            <textarea
                                name='message'
                                onChange={this.onValueChange}
                                value={this.state.message}
                                placeholder='Enter your message*' />

                            <div className='button-progress'>
                                {
                                    this.state.sending && (
                                        <div>
                                            Sending Message...
                                            <LinearProgress className='progress-bar' color='secondary' />
                                        </div>
                                    )
                                }
                                <button
                                    type='submit'
                                    disabled={this.state.disabled || this.state.sending}>
                                    Submit
                                </button>
                            </div>
                        </form>

                    </div>
                </div>

                <div className='contact'>
                    Locations
                </div>
                <div className='contact-section'>
                    <div className='contact-info'>
                        <div className='maps'>

                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2363.3992910816596!2d77.04676644962322!3d28.43514756389431!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1864da20b251%3A0xf828941ab413e779!2s551P%2C%20Sector%2039%2C%20Gurugram%2C%20Haryana%20122001%2C%20India!5e0!3m2!1sen!2suk!4v1644508777858!5m2!1sen!2suk"
                                style={{ width: "100%", height: "500px", border: '0' }}
                                allowFullScreen=""
                                loading="lazy"></iframe>

                        </div>
                    </div>
                    <div className='contact-info-2'>

                        <div className='heading'>
                            Sector 39 Branch - Gurugram
                        </div>
                        <p className='c-title'>

                            We now have three branches, scroll down to see other two branches. Contact Us for more details
                        </p>

                        <hr />
                        <div className='contact-details'>
                            <div>
                                <div className='heading-3'>
                                    <MdContactPhone color='green' />
                                    <span style={{ marginLeft: '10px' }}>Phone</span>
                                </div>
                                <p><a href='tel:09990685643' className='ccnumber'>+91 99906 85643</a></p>
                            </div>

                            <div>
                                <div className='heading-3'>
                                    <MdContactMail color='blue' />
                                    <span style={{ marginLeft: '10px' }}>Email</span>
                                </div>
                                <p><a href='mailto:ContactUs@gamefame.in' className='ccemail'>ContactUs@gamefame.in</a></p>
                            </div>


                        </div>

                        <div className='address'>

                            <div className='heading-3'>
                                <MdWork color='red' />
                                <span style={{ marginLeft: '10px' }}>Address</span>
                            </div>
                            <p>
                                Guru Dronacharya Shooting Academy
                            </p>

                            <p>
                                551-p, Sec-39<br />
                                near Bakhtawar Singh Chowk<br />
                                Gurugram<br />
                                Haryana<br />
                                122001
                            </p>
                        </div>
                    </div>
                </div>
                <div className='contact-section-2'>

                    <div className='contact-info-2'>

                        <div className='heading'>
                            Sector 27 Branch - Gurugram
                        </div>
                        <p className='c-title'>

                            We now have three branches, scroll down and up to see other branches. Contact Us for more details
                        </p>

                        <hr />
                        <div className='contact-details'>
                            <div>
                                <div className='heading-3'>
                                    <MdContactPhone color='green' />
                                    <span style={{ marginLeft: '10px' }}>Phone</span>
                                </div>
                                <p><a href='tel:09990685643' className='ccnumber'>+91 99906 85643</a></p>
                            </div>

                            <div>
                                <div className='heading-3'>
                                    <MdContactMail color='blue' />
                                    <span style={{ marginLeft: '10px' }}>Email</span>
                                </div>
                                <p><a href='mailto:ContactUs@gamefame.in' className='ccemail'>ContactUs@gamefame.in</a></p>
                            </div>


                        </div>

                        <div className='address'>

                            <div className='heading-3'>
                                <MdWork color='red' />
                                <span style={{ marginLeft: '10px' }}>Address</span>
                            </div>
                            <p>
                                GameFame
                            </p>

                            <p>
                                Plot no 143-Basement<br />
                                Sector 27<br />
                                Gurugram<br />
                                Haryana<br />
                                122002
                            </p>
                        </div>
                    </div>
                    <div className='contact-info'>
                        <div id='new-loc' className='maps'>

                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3507.403333332436!2d77.08655826622781!3d28.467397626279762!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d18d0a84d202b%3A0xd87a700162bd0d2e!2s143%2C%20Sector%2027%2C%20Gurugram%2C%20Haryana%20122022%2C%20India!5e0!3m2!1sen!2suk!4v1664552235694!5m2!1sen!2suk"
                                style={{ width: "100%", height: "500px", border: '0' }}
                                allowFullScreen=""
                                loading="lazy"></iframe>

                        </div>
                    </div>
                </div>
                <div className='contact-section'>
                    <div className='contact-info'>
                        <div className='maps'>

                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.446486486337!2d77.184695!3d28.496211600000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1fa61bb8956b%3A0xbe049073d4d2b773!2sAcharya%20Drona%20Shooting%20Academy!5e0!3m2!1sen!2sin!4v1738947300624!5m2!1sen!2sin"
                                style={{ width: "100%", height: "500px", border: '0' }}
                                allowfullscreen=""
                                loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"></iframe>

                        </div>
                    </div>
                    <div className='contact-info-2'>

                        <div className='heading'>
                            Block A1 - Chattarpur
                        </div>
                        <p className='c-title'>

                            We now have three branches, scroll up to see Sector 27 and Sector 29 Gurugram branch . Contact Us for more details
                        </p>

                        <hr />
                        <div className='contact-details'>
                            <div>
                                <div className='heading-3'>
                                    <MdContactPhone color='green' />
                                    <span style={{ marginLeft: '10px' }}>Phone</span>
                                </div>
                                <p><a href='tel:09990685643' className='ccnumber'>+91 99906 85643</a></p>
                            </div>

                            <div>
                                <div className='heading-3'>
                                    <MdContactMail color='blue' />
                                    <span style={{ marginLeft: '10px' }}>Email</span>
                                </div>
                                <p><a href='mailto:ContactUs@gamefame.in' className='ccemail'>ContactUs@gamefame.in</a></p>
                            </div>


                        </div>

                        <div className='address'>

                            <div className='heading-3'>
                                <MdWork color='red' />
                                <span style={{ marginLeft: '10px' }}>Address</span>
                            </div>
                            <p>
                                Acharya Drona Shooting Academy
                            </p>

                            <p>
                                3rd Floor, 619/7<br />
                                Block A1<br />
                                Chattarpur<br />
                                New Delhi<br />
                                110074
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = ({ login }) => {
    return {
        login: login.login
    }
}

export default connect(mapStateToProps)(ContactUs);