import React, { Component } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { MdMenu, MdArrowDropDown } from 'react-icons/md'
import './Nav.css';
import axios from 'axios'
import { connect } from 'react-redux'
import { login } from '../actions/login'
import CookiePermission from './CookiePermission';

class Nav extends Component {

    state = {
        showNav: false,
        userName: '',
        userImage: '',
        cookie: false,
    }

    loginHandler = (response) => {
        const data = axios.post('http://localhost:8888/auth',
            {
                'clientId': response['clientId'],
                'credential': response['credential'],
                'select_by': response['select_by']
            })
        // const data = axios.post('https://gamefame.in:8080/auth', 
        // {'clientId': response['clientId'], 
        // 'credential': response['credential'],
        // 'select_by': response['select_by']})
        data.then(resp => {
            if (resp.status == 200) {
                this.props.dispatch(login(response['credential']))
                this.setState({
                    userName: resp.data['name'],
                    userImage: resp.data['image']
                })
            }
        })

    }

    componentDidMount() {
        /* global google */
        google.accounts.id.initialize({
            client_id: "955028599690-n2g1r75quipj2460lsmeekbukl3kjtrs.apps.googleusercontent.com",
            auto_select: true,
            callback: this.loginHandler
        })

        google.accounts.id.renderButton(
            document.getElementById("googleSignIn"),
            { theme: "outline", size: "large" })

        // google.accounts.id.prompt()

    }

    onNavClick = () => {
        this.state.showNav === 'flex' ?
            this.setState({ showNav: 'none' }) :
            this.setState({ showNav: 'flex' })
    }

    onCookieApprove = () => {
        this.setState({ cookie: true })
    }

    render() {
        return (
            <div>
                {
                    !this.state.cookie && (

                        <CookiePermission onApprove={this.onCookieApprove} />
                    )
                }
                <div className='navbar'>
                    {/* <div className='logocontainer'> */}
                    {/* <div className='logo'> */}

                    {/* <div>G<span className='fstyle'>F</span></div>  */}
                    <div className='company-title'>
                        <Link to='/'>

                            <img src='https://gamefamewebassests.s3.eu-west-2.amazonaws.com/logo.jpg' />
                        </Link>
                        <span className='logoname'>ame<span className='fstyle'>Fame</span> Shooting Academy</span>
                    </div>
                    {/* </div> */}
                    {/* </div> */}
                    <div className='resp-menu' onClick={this.onNavClick}>
                        <MdMenu size={30} />
                    </div>
                    <nav className='menu' style={{ display: this.state.showNav }}>
                        <ul>
                            <li>
                                <Link to='/' onClick={() => { this.setState({ showNav: false }) }}>
                                    Home
                                </Link>
                            </li>
                            <li>

                                <NavLink to=''>
                                    <div><span>Games</span>
                                        <MdArrowDropDown size={20} /></div>
                                </NavLink>
                                <div className='nav-items'>
                                    <Link to='/shooting' onClick={() => { this.setState({ showNav: false }) }}>
                                        Shooting
                                    </Link>
                                    <Link to='/snooker' onClick={() => { this.setState({ showNav: false }) }}>
                                        Snooker
                                    </Link>
                                    <Link to='/gaming' onClick={() => { this.setState({ showNav: false }) }}>
                                        Console Gaming
                                    </Link>

                                </div>

                            </li>
                            <li>
                                <Link to='/gallery' onClick={() => { this.setState({ showNav: false }) }}>
                                    Gallery
                                </Link>
                            </li>
                            <li>
                                <Link to='/events' onClick={() => { this.setState({ showNav: false }) }}>

                                    Events
                                </Link>
                            </li>
                            <li>
                                <Link to='/contact' onClick={() => { this.setState({ showNav: false }) }}>
                                    Contact Us
                                </Link>
                            </li>

                            {
                                this.state.userName === '' && (
                                    <div id="googleSignIn"></div>
                                )
                            }

                            {
                                this.state.userName !== '' && (
                                    <div className='profile-data'>
                                        <span>Howdy, {this.state.userName}</span>
                                        <img src={this.state.userImage} />
                                    </div>
                                )
                            }
                        </ul>
                    </nav>

                </div>
            </div>
        )
    }

}

const mapStateToProps = ({ login }) => {
    return {
        login: login.login
    }
}

export default connect(mapStateToProps)(Nav);